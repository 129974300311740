import * as React from 'react';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { loadTheme } from '@fluentui/style-utilities';
import { CoherenceTheme } from '@coherence-design-system/styles';

const StyledThemeProvider = styled(ThemeProvider)`
    height: 100%;
    display: flex;
    flex-direction: column;

    body {
        margin: 0px;
    }
`;

export function CoherenceThemeProvider(
    props: React.PropsWithChildren<unknown>
): React.ReactElement {
    loadTheme(CoherenceTheme);

    return <StyledThemeProvider>{props.children}</StyledThemeProvider>;
}
