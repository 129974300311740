import { useEffect, useContext } from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { requestNav } from './Nav.actions';
import { IManagerHubAppState } from '../../../App.types';
import { NavReducerName } from './Nav.resources';
import { initialNavState } from './Nav.reducer';
import { INavItem } from './Nav.types';
import { createSelector } from 'reselect';
import { INavLinkGroup } from '@cseo/controls/lib/nav';

export const selectNavItems = createSelector(
    (state: IManagerHubAppState) => state[NavReducerName] ?? initialNavState,
    ({ items }) => ({
        key: 'main',
        links: items.map((i: INavItem) => ({
            ...i,
        })),
    })
);

export function useNav(): INavLinkGroup {
    const { dispatch, useSelector } = useContext(ReduxContext);
    const nav: INavLinkGroup = useSelector(selectNavItems);

    useEffect(() => {
        dispatch(requestNav());
    }, [dispatch]);

    return nav;
}
