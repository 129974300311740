export const Page = styled.div`
    position: relative;
    height: auto;
    max-width: 1872px;
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
`;
