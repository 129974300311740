import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CoherenceCustomizations } from '@cseo/styles/lib/CoherenceCustomizations';
import { Customizer } from 'office-ui-fabric-react/lib/Utilities';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { Main } from '@employee-experience/shell/lib/Main';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Footer } from '@employee-experience/shell/lib/Footer';
import { Footer as ManagerHubFooter } from './Shared/Components/Footer';
import { ShellWithStore } from './ShellWithStore';
import { Header } from './Shared/Components/Header';
import { Routes } from './Routes';
import { Nav } from './Shared/Components/Nav';
import { initializeCustomIcons } from './Shared/initializeCustomIcons';
import { initializeCentro } from '@coherence-design-system/controls';
import { CoherenceThemeProvider } from './CoherenceThemeProvider';

initializeCustomIcons();

// The locale of the HVC to be loaded, in BCP-47 format
const language = 'en';

function App(): React.ReactElement {
    useLoginOnStartup();

    React.useEffect(() => {
        // initializeCentro(__OCV_APP_NAME__, language, __OCV_APP_ID__);
    }, []);

    return (
        <CoherenceThemeProvider>
            <BrowserRouter>
                <Header />
                <Nav />
                <Main>
                    <Routes />
                </Main>
                <Footer>
                    <ManagerHubFooter />
                </Footer>
            </BrowserRouter>
        </CoherenceThemeProvider>
    );
}

render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);
