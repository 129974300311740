import * as React from 'react';
import { Page } from '../../Shared/Components/Layout/Page';
import { ComponentProvider } from '@employee-experience/common/lib/ComponentProvider';
import { PageHeading } from '../../Shared/Components/Typography/PageHeading';

export function ManagerHubDashboard(): React.ReactElement {
    return (
        <Page>
            <PageHeading>Dashboard</PageHeading>

            <ComponentProvider
                config={{
                    script: `${__BASE_URL__}/bundles/manager-dashboard.js`,
                    name: 'ManagerDashboard',
                }}
            />
        </Page>
    );
}
