import { IAuthClient, IHttpClientRequest } from '@employee-experience/common';
import jwt_decode from 'jwt-decode';

export async function getUserJWTInfo(authClient: IAuthClient): Promise<{}> {
    let jwtInfoObj = {};

    const decodeJWTInfo = async (): Promise<IHttpClientRequest> => {
        const graphResourceUri = 'https://graph.microsoft.com';
        const token = await authClient.acquireToken(graphResourceUri);
        const decoded = jwt_decode(token);
        return decoded;
    };

    try {
        jwtInfoObj = decodeJWTInfo();
    } catch (err) {
        console.log(err);
    }

    return jwtInfoObj;
}
