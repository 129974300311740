import { getAPICallFeature } from '@employee-experience/common/lib/Helpers/UsageTelemetryHelper';
import { getContext, put, all, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { IHttpClient, UsageEventName, EventType } from '@employee-experience/common/lib/Models';
import { receiveNav } from './Nav.actions';
import { ActionTypes } from './Nav.action-types';
import { mapNav } from './Nav.utils';
import { getSharedHttpConfig } from '../../getSharedHttpConfig';
import { INavItemsResponse } from './Nav.types';

const adminHub: INavItemsResponse = {
    '/contents': {
        navText: 'Content',
        navIcon: 'Edit',
        bundleLocation: '/bundles/content-management.js',
        componentName: 'ContentManagement',
    },
    '/cards': {
        navText: 'Cards',
        navIcon: 'WebComponents',
        bundleLocation: '/bundles/component-management.js',
        componentName: 'ComponentManagement',
    },
    '/pages': {
        navText: 'Pages',
        navIcon: 'WebTemplate',
        bundleLocation: '/bundles/dashboard-management.js',
        componentName: 'DashboardManagement',
    },
};

function* fetchNav(): SagaIterator {
    const httpClient: IHttpClient = yield getContext('httpClient');
    if (__IS_ADMIN_HUB__) {
        yield put(receiveNav(mapNav(adminHub)));
    } else {
        const { data } = yield call(
            [httpClient, httpClient.request],
            getSharedHttpConfig(`${__CONFIG_API_BASE_URL__}/api/dashboards/routes`),
            {},
            getAPICallFeature(
                {
                    feature: 'ManagerHub.Nav',
                    subFeature: 'PlatForm',
                },
                'GetRoutesAPI'
            )
        );

        const isAdmin = data.hasOwnProperty('/cards');
        if (isAdmin) {
            for (const key in adminHub) {
                data[key] = adminHub[key];
            }
        }

        yield put(receiveNav(mapNav(data)));
    }
}

export function* navSaga(): SagaIterator {
    yield all([takeEvery(ActionTypes.REQUEST_NAV, fetchNav)]);
}
