import * as React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { RouteComponentProvider } from '@employee-experience/common/lib/RouteComponentProvider';
import { useIsAdmin } from './Shared/useIsAdmin';
import { ManagerHubDashboard } from './Pages/ManagerDashboard/ManagerHubDashboard';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/dashboard" component={ManagerHubDashboard} />
            <RouteComponentProvider
                path="/search"
                config={{
                    script: `${__BASE_URL__}/bundles/search.js`,
                    name: 'Search',
                }}
            />

            <Redirect exact from="/" to="/pages" />
            <RouteComponentProvider
                path="/cards"
                config={{
                    script: `${__BASE_URL__}/bundles/component-management.js`,
                    name: 'ComponentManagement',
                }}
            />
            <RouteComponentProvider
                path="/contents"
                config={{
                    script: `${__BASE_URL__}/bundles/content-management.js`,
                    name: 'ContentManagement',
                }}
            />
            <RouteComponentProvider
                path="/pages"
                config={{
                    script: `${__BASE_URL__}/bundles/dashboard-management.js`,
                    name: 'DashboardManagement',
                }}
            />
        </Switch>
    );
}
