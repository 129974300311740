import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CoherenceHeader } from '@coherence-design-system/controls/lib/header/CoherenceHeader';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import {
    IFeedbackInitOptions,
    FeedbackAuthenticationType,
    FeedbackAgeGroup,
    FeedbackUiType,
    OCVInitialization,
    initializeOCVFeedback,
    FarRightFeedbackPanelProps,
    FeedbackPolicyValue,
} from '@coherence-design-system/controls';
import { HeaderRoot } from './Header.styled';
import { useGraphPhoto, useUser } from '@employee-experience/common';
import { getUserJWTInfo } from '../../Utils/getUserJWTInfo';
import { useEffect, useState } from 'react';

export function Header(): React.ReactElement {
    const { authClient } = React.useContext(ComponentContext);
    const history = useHistory();
    const user = useUser();
    const photo = useGraphPhoto();
    const [tenant, setTenant] = useState('');
    const [ocvButtonIsFocused, setOcvButtonIsFocused] = React.useState(false);

    useEffect(() => {
        getUserJWTInfo(authClient).then((tenantInfo) => {
            setTenant(tenantInfo.tid);
        });
    }, [authClient]);

    const handleSearched = (value: string): void => {
        history.push(`/search?q=${encodeURIComponent(value)}`);
    };

    const feedbackOptions: IFeedbackInitOptions = {
        // Please refer to our document for more details on FeedbackInitOptions
        // Or see the below link for the completed parameters sample
        // https://office.visualstudio.com/OC/_git/M365AdminUX?path=/docs/centro/consume/hvcs/ocv-inapp-feedback-example/react-sample/src/inapp-feedback/defaultFeedbackOptions.ts
        appId: __OCV_APP_ID__,
        clientName: __OCV_APP_NAME__,
        authenticationType: FeedbackAuthenticationType.AAD, //authenticationType must be dynamically fetched from the host app
        ageGroup: FeedbackAgeGroup.Adult, //agegroup must be dynamically fetched from the host app
        callbackFunctions: {
            onDismiss: (_isFeedbackSent?: boolean) => {
                console.log('Dismissing OCV panel');
                _handleOcvButtonFocusState();
            },
            onError: (_isFeedbackSent?: string | undefined) => {
                console.log('OCV panel has an error');
                _handleOcvButtonFocusState();
            },
        },
        feedbackConfig: {
            feedbackUiType: FeedbackUiType.SidePane,
            isDisplayed: false,
            isEmailCollectionEnabled: true,
            isFileUploadEnabled: true,
            isScreenshotEnabled: true,
            isScreenRecordingEnabled: true,
            complianceChecks: {
                // Required for AAD authenticationType
                connectedExperiences: FeedbackPolicyValue.Enabled,
                policyAllowContact: FeedbackPolicyValue.Enabled,
                policyAllowContent: FeedbackPolicyValue.Enabled,
                policyAllowFeedback: FeedbackPolicyValue.Enabled,
                policyAllowScreenshot: FeedbackPolicyValue.Enabled,
                policyAllowSurvey: FeedbackPolicyValue.Enabled,
            },
        },
        themeOptions: { baseTheme: 'WindowsLight' }, // this is required. Use the theme that suits your app,
        tenantId: typeof tenant === 'string' ? tenant : 'd0000000-0000-0000-0000-000000000000',
        userId: user?.id || 'loggableUserId',
    };
    const _handleOcvButtonFocusState = () => {
        const overflowButton = document.getElementById('coher-header-overflow-button-id');
        if (overflowButton) {
            overflowButton.focus();
        } else {
            const ocvButton = document.getElementById('coher-header-ocv-button-id');
            if (ocvButton) {
                ocvButton.focus();
            }
        }

        setOcvButtonIsFocused(false);
    };

    const ocvInit: OCVInitialization = {
        appId: __OCV_APP_ID__,
        env: process.env.NODE_ENV === 'production' ? 'Prod' : 'Int',
        scriptUrl: '/ocv/officebrowserfeedback.min.js',
        stylesUrl: '/ocv/officebrowserfeedback.min.css',
        intlUrl: '/intl/',
        onError: (error: string) => {
            console.log('An error has occured during submission: ' + error);
            _handleOcvButtonFocusState();
        },
        telemetryGroup: { featureArea: 'Portal site' },
        onDismiss: (submitted: boolean) => {
            if (submitted) {
                console.log('Your feedback has been submitted');
            }
            _handleOcvButtonFocusState();
        },
    };

    const feedbackConfig: FarRightFeedbackPanelProps | undefined = {
        buttonSettings: {
            title: 'Feedback',
            ariaLabel: 'Feedback',
        },
        panelSettings: {
            ocvButtonIsFocused: ocvButtonIsFocused,
            onClick: () => {
                setOcvButtonIsFocused(true);
                return true;
            },
            launchOptions: {
                categories: {
                    show: false,
                    customCategories: [],
                },
            },
            ocvInitOptions: feedbackOptions,
        },
    };
    initializeOCVFeedback(ocvInit);

    return (
        <HeaderRoot>
            <CoherenceHeader
                headerLabel={__APP_NAME__}
                appNameSettings={{
                    label: __APP_NAME__,
                }}
                searchSettings={{
                    placeholder: `Search ${__HRWEB_DISPLAY_NAME__} and ${__MANAGERHUB_DISPLAY_NAME__}...`,
                    onSearch: handleSearched,
                }}
                farRightSettings={{
                    feedbackSettings: __IS_ADMIN_HUB__ ? undefined : feedbackConfig,
                    profileSettings: {
                        panelSettings: {
                            fullName: user?.name ?? '',
                            emailAddress: user?.email ?? '',
                            imageUrl: photo || undefined,
                            logOutLink: 'Sign Out',
                            hideSignOutDivider: true,
                        },
                    },
                }}
            />
        </HeaderRoot>
    );
}
