import * as React from 'react';
import * as Styled from './Footer.styled';
import { Link } from '@employee-experience/common/lib/Link';
import { Container } from '../Container';

export function Footer(): React.ReactElement {
    const year = new Date().getFullYear();

    return (
        <Styled.FooterRoot>
            <Container style={{ width: 'fit-content' }}>
                <Styled.FooterText>
                    Manager Hub contains only general information and guidelines, which may vary by
                    country and by subsidiary. It does not create any contractual rights or impose
                    legal obligations on Microsoft, nor does it guarantee specific treatment in any
                    given situation. Manager Hub is updated frequently and is subject to change at
                    any time. Employees should check with their HR representative for clarification.
                </Styled.FooterText>

                <Styled.FooterMenus>
                    <Styled.FooterMenuItem>
                        <Link
                            to="http://go.microsoft.com/fwlink/?LinkId=518021"
                            title="Microsoft Data Privacy Notice"
                            target="_blank"
                        >
                            Microsoft Data Privacy Notice
                        </Link>
                    </Styled.FooterMenuItem>
                    <Styled.FooterMenuItem>
                        <Link
                            to="https://microsoft.sharepoint.com/sites/hrw/Pages/Legal-Notice.aspx"
                            title="Legal notice"
                            target="_blank"
                        >
                            Legal Notice
                        </Link>
                    </Styled.FooterMenuItem>
                    <Styled.FooterMenuItem>&copy; {year} Microsoft</Styled.FooterMenuItem>
                </Styled.FooterMenus>
            </Container>
        </Styled.FooterRoot>
    );
}
